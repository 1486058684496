/* src/styles.css */
.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  
  .mybold {
    font-weight: bold;
  }
  
  .middle-content {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
  }
  
  .middle-content div {
    padding: 16px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .left-column-content {
    background-color: #007bff;
    color: #fff;
  }
  
  .right-column-content {
    background-color: #28a745;
    color: #fff;
  }
  /** Animate Block**/
  .left-evaluation {
    position: absolute;
    left: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }
  
  .left-teacher-asset {
    position: absolute;
    left: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }
  
  .right-academic-performance{
    position: absolute;
    right: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }
  
  .right-curriculum-teaching{
    position: absolute;
    right: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }
  
  
  .right-div {
    position: absolute;
    left: 400px;
    top: 50px;
    width: 100px;
    height: 50px;
    background-color: #2196F3;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 99;
  }
  
  .electron {
    fill: #FF5733;
    /*animation: moveElectron 2s linear infinite;*/
  }
  
  @keyframes moveElectron {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(250px, 0);
    }
  }
  
  /* Rating table.css */
  .rating-table {
    width: 250px;
    height: 300px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    margin: 0 auto;
    background-color: #fff;
    font-size: 9px;
    color: black;
    table-layout: fixed; /* Ensure text wrapping */
  }
  
  .rating-table th,
  .rating-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
    overflow-wrap: break-word; /* Ensure text wrapping */
    word-wrap: break-word; /* Ensure text wrapping */
    white-space: normal; /* Ensure text wrapping */
    font-weight: bold;
  }
  
  .rating-table th {
    background-color: #f1f1f1;
    font-weight: bold;
  }
  
  .rating-table tbody td {
    background-color: #fff;
  }
  
  .rating-table thead th {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
  }
  
  .rating-table tfoot td {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
  }
  
  /* custom-select.css */
  .custom-select {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
    color: #495057;
    background-color: #ffffff;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .custom-select:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
  
  /** making things required or exclusive**/
  .myasterisk{
    font-weight: bolder;
    font-size: 14px;
  }
  